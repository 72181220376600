
.CustomModal {

  --bs-modal-body-background-image: url("./assets/popup.png");
  --bs-modal-background-size: 600px;
  background-color: rgba(0, 0, 0, 0.75);
  --bs-modal-zindex:1055;
  --bs-modal-width:650px;

  --bs-modal-padding:1rem;
  --bs-modal-margin:0.5rem;
  --bs-modal-color: ;
 
  --bs-modal-border-color:var(--bs-border-color-translucent);
  --bs-modal-border-width:0px;--bs-modal-border-radius:0.5rem;
  --bs-modal-box-shadow:0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius:calc(0.5rem - 1px);
  --bs-modal-header-padding-x:1rem;
  --bs-modal-header-padding-y:1rem;
  --bs-modal-header-padding:1rem 1rem;
  --bs-modal-header-border-color:var(--bs-border-color);
  --bs-modal-header-border-width:0px;--bs-modal-title-line-height:1.5;
  --bs-modal-footer-gap:0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color:var(--bs-border-color);
  --bs-modal-footer-border-width:0px;
  position:fixed;
  top:0;
  left:0;
  z-index:var(--bs-modal-zindex);
  display:none;
  width:100%;
  height:100%;
  overflow-x:hidden;
  overflow-y:auto;
  outline:0}
 
.modal-dialog{position:relative;width:auto;margin:var(--bs-modal-margin);pointer-events:none}.modal.fade .modal-dialog{transition:transform .3s ease-out;transform:translate(0,-50px)}@media (prefers-reduced-motion:reduce){.modal.fade .modal-dialog{transition:none}}.modal.show .modal-dialog{transform:none}.modal.modal-static .modal-dialog{transform:scale(1.02)}.modal-dialog-scrollable{height:calc(100% - var(--bs-modal-margin) * 2)}.modal-dialog-scrollable .modal-content{max-height:100%;overflow:hidden}.modal-dialog-scrollable .modal-body{overflow-y:auto}.modal-dialog-centered{display:flex;align-items:center;min-height:calc(100% - var(--bs-modal-margin) * 2)}

