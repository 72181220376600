

.sectipop
{
  width: 600px;
  height: auto;
  font-family: "Arial", cursive;
  opacity: 1;
  font-size: 1.2em;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
 
    width: 80vw;
       
    }
}


.sectipop2
{
  width: 600px;
  height: auto;
align-items: center;
  opacity: 1;
  justify-content: center;

}

.secti
{
  
  font-family: "Caveat Brush", cursive;
  opacity: 1;
 

}
.visible
{
  color: gray;
  font-family: "Caveat Brush", cursive;
font-size: 1em;
  opacity: 1;

}

.disp
{
  padding: 20px;
  padding-bottom: 0px;
  font-family: "Caveat Brush", cursive;
font-size: 1.4em;
text-align: center;
align-items: center;

}
.disp2
{
  margin-top: 20px;
  padding: 20px;
text-align: center;
align-items: center;

}

.navi
{
  
  font-family: "Caveat Brush", cursive;
font-size: 1.4em;

}

.pick
{
  
  opacity: 1;

}
.nopick
{
  
  opacity: 0.5;

}

.backg
{
 
  transition: 1s all;
  opacity: 1;

}

.backtest
{
  width: 100vw;
  height: auto;
  background-image: url("./assets/map.png");

}

.hide
{
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  transform: scale(0.5);
}
.hiddenav
{
  display: none;
}

.boobs
{
transition: 0.8s all;
display:none;

}

.backb
{ 
transition: 1.3s all;
display:flex;


}

.progress-bar{
  height: 40px;
  width: 220px;
  font-size: 10px;
  font-size: x-large;
  text-align: center;
  background-color: #3a9042;
  }
  .progress
  {
    width: 200px;
    height: 30px;
    
  }
  
  .progress span {
    color: #FFFFFF;
      position: absolute;
      display: block;
      width: 100%;
      
   }

   .dead {

    opacity: 0.4;
   }

   .killed {
    font-size: 2em;
    color: red;
    top: 30%;
    left: 20%;
   
  }

  
  
  .modal-body{
   background-color: #FFFFFF;
   border-radius: 20px;
   width: 650px;
   margin-top: 80px;
   flex-wrap: wrap;
   flex-direction: column;
   @media (max-width: 600px) {
    margin-top: 20px;
    width: 90vw;
       
    }
  }

  .modaltitle
  {
    margin-top: 30px;
    margin-left: 90px;
    justify-content: center;
    align-items: center;
    font-family: "Caveat Brush", cursive;
    font-size: 1.8em;
    

  }
  .modalsubtitle
  {
    width: 650px;
    
    justify-content: center;
    align-items: center;
    font-family: "Arial", cursive;
    font-size: 1.4em;
    text-align: center;

  }

  .modalsubtitle2
  {
    width: 600px;
    margin-top: 40px;
    padding-left: 50px;
    padding-right: 50px;
   
   
    justify-content: center;
    align-items: center;
    font-family: "Arial", cursive;
    font-size: 1.7em;
    text-align: center;

  }
  .modalimage
  {
    margin-top: 0px;
    margin-left: 150px;
    justify-content: center;
    align-items: center;
    font-family: "Arial", cursive;
    font-size: 1.8em;

  }

  .knappcolumn
  {
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
  
    
  }

  .knappcolumn2
  {
  
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
  }

.bigbox {
font-family: "Caveat Brush", cursive;
margin-top: 8px;
margin-right: 40px;
font-size: 2em;
color: #000000;
display: flex;
flex-direction: column;
opacity: 1;
justify-content: center;
align-items: center;
}